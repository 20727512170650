// Theme Colors
// $secondary: #5827D0;
// $secondary-light: #C2ABFA;
// $secondary-color-light: #F3EEFF;
// $primary: #2AAC7F;
// $primary-light: #85bba1;

// Edit, Delete & Warning Colors
$edit-dark: #0EA36D;
$edit-light: #E7FFF7;
$red-color: #ff0000;
$red-color-light: #ffd9d9;
$warning-color: #d2e82f;
$in-progress-color: #F49025;

// Common Colors
$black-color: #000000;
$black-shade: #222222;
$white-color: #ffffff;

// Shade Colors
$shade1: #eeeeee;
$shade2: #cccccc;
$shade3: #707070;
$shade4: #808080;
$shade1-light: #eeeeee8f;
$shade2-light: #ffffff30;
$shade5: #00000040;

