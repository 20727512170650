@import "variables";
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700&display=swap');
body {
    background-color: $white-color !important;
}

.MuiSwitch-colorPrimary.Mui-checked,
.MuiSwitch-colorSecondary.Mui-checked {
    color: var(--secondary) !important;
}
.MuiSwitch-colorPrimary.Mui-checked ,
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
   color: var(--secondary) !important;
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: var(--secondary-light) !important;
}
.MuiSwitch-thumb {
    box-shadow: none !important;
}
span.back-text {
    font-weight: bold;
    text-transform: capitalize;
    margin-left: 3px;
    font-size: 16px;
}
.MuiChip-colorPrimary {
    background-color: var(--secondary) !important;
}

// No Data Found
.no-data {
    img {
        max-width: 150px;
        display: inline-block;
    }
    h5 {
        margin-top: 10px;
        font-size: 16px;
    }
}

.delivery-partner {
    .no-data {
        img {
            max-width: 200px;
        }
    }
}

// Navigation Css Here
ul.MuiList-root.main-nav.MuiList-padding {
    padding-top: 20px !important;
    .menu-items-list-view {
        border-bottom: 1px solid $shade1  !important;
        border-left: 0 solid $white-color !important;
        height: auto;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 22px;
        .MuiListItemIcon-root {
            min-width: auto;
            margin-right: 26px;
        }
        &:hover {
            background-color: $black-color !important;
            color: $white-color !important;
            border-left: 0px solid $black-color !important;
            border-color: $black-color !important;
            svg {
                color: $white-color;
            }
            span {
                color: $white-color;
            }
        }
    }
    .active-drawer {
        background-color: $black-color !important;
        color: $white-color !important;
        border-left: 0px solid $black-color !important;
        border-color: $black-color !important;
        svg {
            color: $white-color;
        }
        span {
            color: $white-color;
        }
    }
}

// Dashboard Css Here
.dashboard-container {
    height: 100% !important;
}
.main-loader {
    height: 100vh !important;
    .Loader__background {
        z-index: 9999 !important;
    }
    .Loader__content {
        height: 100vh;
    }
}
.login-background-contianer {
    background-color: $white-color;
    .login-form {
        max-width: 600px;
    }
    .MuiPaper-root {
        -webkit-box-shadow: 0 0 30px $shade1;
        -moz-box-shadow: 0 0 30px $shade1;
        box-shadow: 0 0 30px $shade1;
        border-radius: 0;
    }
    &::before {
        content: "";
        display: inline-block;
        max-width: 350px;
        width: 100%;
        height: 450px;
        position: absolute;
        top: 0;
        right: 20px;
        z-index: 0;
    }
    &:after {
        content: "";
        display: inline-block;
        max-width: 350px;
        width: 100%;
        height: 450px;
        position: absolute;
        bottom: 0;
        left: 20px;
        z-index: 0;
    }
    .login-container {
        .login-container-item {
            height: auto !important;
            margin: auto;
            z-index: 1;
            line-height: 0;
            .full-height {
                height: 100%;
            }
            .MuiFormControl-root {
                margin-bottom: 30px;
                p.Mui-error{
                    margin: 0;
                    position: absolute;
                    bottom: -20px;
                    left: 0;
                }
            }
            button {
                margin: 0;
                background-color: $black-color;
                color: $white-color;
            }
            .forget-password {
                clear: both;
                line-height: normal;
                cursor: pointer;
                float: right;
                margin-top: -20px;
                margin-bottom: 30px;
                color: $black-shade;
                text-decoration: underline;
                &:hover{
                    color: $black-color;
                    text-decoration: none;
                }
            }
        }
        .login-logo-div {
            width: 100%;
            margin-bottom: 30px;
            text-align: center;
            img {
                width: 100%;
                max-width: 200px;
                display: inline-flex;
                margin-left: -10px;
            }
        }
        .form-container {
            .login-button {
                &:disabled {
                    background-color: var(--secondary) !important;
                }
            }
        }
        .form-container-item {
            margin: auto;
        }
    }
}
.add-button {
    button {
        height: 48px !important;

    }
}

// Product Common Card Preview
.cus-pro-list {
    .flower-type {
        color: $white-color;
        top: 0;
        position: absolute;
        left: 7px;
        right: auto;
        font-weight: 500;
        padding: 15px 2px 0;
        font-size: 16px;
        z-index: 2;

        &:before {
            content: "";
            background-color: var(--secondary);
            display: inline-block;
            width: 167px;
            height: 130px;
            -webkit-transform: rotate(-32deg);
            -moz-transform: rotate(-32deg);
            transform: rotate(-32deg);
            position: absolute;
            top: -78px;
            left: -63px;
            z-index: -1;
        }
    }
    .pro-img {
        display: flex;
        img {
            display: inline-block;
            width: auto;
            object-fit: contain;
            margin: 0 auto;
        }
    }
    .pro-card-header {
        .pro-mood {
            display: flex;
            align-items: center;

            img {
                margin: 0 10px 0 0;
                width: 23px;
                height: auto;
            }

            p {
                font-size: 12px;
                color: $black-color;
            }
        }

        .pro-title {
            width: 100%;
            display: inline-block;
            margin: 0 0 5px 0;

            h5 {
                font-size: 16px;
                color: $black-color;
                line-height: normal;
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                line-height: normal;
                font-weight: 600;
                text-align: left;
                margin: 0;
            }
        }

        .pro-price {
            width: 100%;
            display: inline-block;
            margin: 0;
            text-align: left;

            .pro-price-amount {
                color: $black-color;
                font-weight: 600;
                font-size: 16px;

                .price-currency-symbol {
                    margin-right: 1px;
                }
            }

            span{
                &.pro-price-amount-del {
                    color: $shade3;
                    text-decoration: line-through;
                    margin: 0 10px 0 0;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }

    }
}
.color-red {
    svg {
        color: $red-color;
    }
}
.custom-menu-p-tag {
    display: flex;
    button {
        padding: 0;
        span {
            font-size: 14px;
        }
    }
}
.cus-table-dropdown {
    .MuiIconButton-label {
        color: $shade2;
    }
}

.no-data-found {
    p {
        margin: 0;
    }
}

.material-ui-icons {
    cursor: pointer;
    font-size: 20px !important;
}

// Table Design Css Here
.cus-table {
    -webkit-box-shadow: 0 0 30px $shade1;
    -moz-box-shadow: 0 0 30px $shade1;
    box-shadow: 0 0 30px $shade1;
    border-radius: 0;
    .box-shadow-none {
        box-shadow: none;
    }
    .MuiTableCell-head {
        white-space: nowrap;
        text-transform: capitalize;
        font-weight: bold;
        padding: 20px !important;
        background-color: $black-color ;
        color: $white-color;
    }
    table{
        thead, tbody{
            tr{
                vertical-align: top;
                td{
                    white-space: nowrap;
                    &:last-child{
                        > div {
                            white-space: nowrap;
                        }
                    }
                }
                th, td{
                    border: none;
                    vertical-align: middle;
                }
                .no-data {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 477px;
                    flex-direction: column;
                }
            }
        }
    }
}
a{
    &.link-tag{
        &.btn {
            background-color: $black-color;
            display: inline-block;
            color: $white-color;
            padding: 0px 20px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            height: 36px;
            line-height: 36px;
            &:hover {
                background-color: $edit-dark;
            }
        }
    }
}

span{
    &.text-top {
        display: block;
        text-align: left;
        text-transform: capitalize;
        color: $edit-dark;
    }
}
body {
    .MuiPaper-elevation1, .add-notification-page {
        -webkit-box-shadow: 0 0 30px $shade1;
        -moz-box-shadow: 0 0 30px $shade1;
        box-shadow: 0 0 30px $shade1;
        border-radius: 0;
    }
    .MuiInputBase-root {
        fieldset {
            border-radius: 0;
            border-color: $shade4 !important;
        }
    }
    // .MuiBackdrop-root {
    //     background-color: rgba(0, 0, 0, 0.2);
    // }
    .page-heading {
        position: fixed;
        left: 355px;
        top: 16px;
        z-index: 1100;
    }
    .css-jksn1m{
        + div{
            .page-heading {
                left: 75px;
            }
        }
    }
}

.account-cannamobile {
    .logo-theme {
        background: $white-color;
    }
}

// Add Categories Modal Css Here
.cus-modal {
    // z-index: 999999 !important;
    .add-button {
        margin-top: 16px;
        margin-bottom: 16px;
    }
}

// Terminal Css Here
.cust-google-field {
    width: 100% !important;
}

.tabs-section-div {
    margin: 10px auto;
    width: 400px;
    display: flex;
    -webkit-box-shadow: 0 0 30px $shade1;
    -moz-box-shadow: 0 0 30px $shade1;
    box-shadow: 0 0 30px $shade1;
    div {
        width: 50%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.selected-tab {
            cursor: default !important;
            background: $black-color;
            color: $white-color;
        }
    }
}

.simplebar-content {
    background: var(--primary-theme-dark);
}
.simplebar-content {
    .logo-theme  {
        + div {
        height: 100vh;
        }
    }
}

.css-jjtu05 {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
    background: var(--primary);
}

.css-dxfs78 {
    overflow-x: hidden;
    height: 100%;
    background: var(--primary);
}
.nav-list {
    padding: 0px !important;
    max-height: calc(100% - 70px);
    overflow: hidden;
    overflow-y: auto;
    background: transparent;
    li {
        display: flex;
        margin: 0 0 10px;
        a {
            font-weight: 700;
            border-radius: 5px;
            height: 32px;
            background: transparent;
            color: $white-color;
            &:hover, &.active {
                color: var(--primary-theme);
                background: transparent;
                .svg-color{
                    background: var(--primary-theme);
                }
            }
            .svg-color{
                background: $white-color;
                width: 18px;
                object-fit: contain;
            }
        }

    }
}
.logout{
    li {
        overflow: hidden;
        overflow-y: auto;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 280px;
        padding: 12px 20px;
        color: $white-color;
        font-weight: 700;
        border-radius: 0;
        cursor: pointer;
        background: #4a4a4a;
        &:hover{
            border-radius: 0px;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            -ms-border-radius: 0px;
            -o-border-radius: 0px;
             background: var(--primary-theme);
        }
    }
}
.account-hyperwolf, .account-westcoast{
    .nav-list {
        li {
            // a {
            //     &:hover, &.active {
            //         color: var(--primary-theme-dark);
            //         .svg-color{
            //             background: var(--primary-theme-dark);
            //         }
            //     }
            // }
        }
    }
}

header{
    .MuiToolbar-regular {
        min-height: 69px;
    }
}

.manage-top-tabs {
    div {
        color: $black-color;
        background-color: $white-color;
        text-transform: capitalize !important;
    }
}
.box-style {
    margin-bottom: 0 !important;
}
.search-input{
    max-width: 400px;
    min-width: 400px;
    input {
        padding-left: 0 !important;
      }
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: var(--secondary) !important;
}

/* Custom Code Here */
.MuiChip-colorPrimary {
    color: $white-color;
}
.link-tag {
    text-decoration: none;
    color: var(--primary) ;
    &.inactive-tag {
        background-color: $white-color !important;
        cursor: default !important;
    }
}
smaller-adornment .MuiSvgIcon-root {
    width: 0.75em;
    height: 0.75em;
  }

  .smaller-adornment svg {
    font-size: 0.875rem;
  }


.category-ul-list {
    padding: 0;
    list-style: none;
    margin: 0;
    li {
        &.custon-list {
            font-size: 16px;
            width: 100%;
            display: block;
            border-radius: 4px;
            position: relative;
            text-transform: capitalize;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            span{
                &.menu-icon {
                    position: absolute;
                    right: 16px;
                    top: 15px;
                }
            }
            .MuiChip-root {
                background-color: var(--secondary-light) ;
                .MuiChip-label {
                    color: $black-color;
                }
                .MuiChip-deleteIcon {
                    color: $black-color;
                    &:hover {
                        color: $black-color;
                    }
                }
            }
        }
        .category-img-sec {
            height: 60px;
            width: 60px;
            border: 0;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            padding: 5px;
            align-items: center;
            .category-img {
                object-fit: contain;
                width: 100%;
                height: 100%;
                margin-right: 12px;
            }
        }
        .left-section {
            margin-right: 10px;
            width: 300px;
        }
        .category-sec-list-drag {
            padding: 10px;
        }
    }
}

.manage-product-section {
    .search-section {
        .MuiOutlinedInput-root {
            background-color: $white-color;
        }
    }
}
.flowertype-list {
    img {
        height: 20px;
        width: 20px;
        margin: 0px 0 -5px 5px;
    }
}

.modal-title {
    position: absolute;
    right: 0;
    top: -10px;
}

.cus-modal {
    button{
        &.close {
            background-color: transparent;
            right: -10px;
            padding: 0;
            color: $black-color;
            z-index: 999;
            top: 5px;
        }
    }

    h4 {
        &.jc-ctr {
            font-size: 26px;
            text-transform: uppercase;
            font-weight: 600;
            margin: 0px 0 0 15px;
        }
    }
    .add-category-input {
        padding: 20px 0;
        .browse-list {
            margin-bottom: 20px;
            border: 1px dotted $shade2;
            border-radius: 4px;
            padding: 15px;
            text-align: center;
            &:focus {
                outline: none;
            }
            .drop-files {
                &:focus {
                    outline: none;
                }
            }
        }
        .category-images-container {
            .category-image-item-1 {
                border-right: 1px solid $shade2;
                padding-right: 5px;
            }
            .category-image-item-2 {
                padding-left: 5px;
            }

            .inner-select-image-item {
                position: relative;
            }
            .select-category-image {
                padding: 10px 5px;
                border: 1px solid $shade2;
                border-radius: 4px;
                margin: 5px;
                height: 60px;
                cursor: pointer;
                display: flex;
                img {
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
                &.selected {
                    width: 80px;
                    height: 80px;
                    border-color: var(--primary)  !important;
                    border-width: 2px;
                    height: 140px;
                }
                &.disable-select {
                    border-color: var(--primary) ;
                    border-width: 2px;
                    cursor: default;
                    img {
                        max-width: 102%;
                        max-height: 102%;
                    }
                }
            }
            .tick-placement {
                position: absolute;
                right: 0;
                svg {
                    color: var(--primary) ;
                }
            }
        }
    }

    .cus-label {
        margin-bottom: 0;
        text-align: center;
    }
    .add-category-input {
        .category-image-item-2 {
            display: flex;
            align-items: center;
            justify-content: center;
            .select-category-image {
                    width: 180px;
                    padding: 10px 5px;
                    border: 1px solid $shade2;
                    border-radius: 4px;
                    margin: 5px 20px 0 0;
                    cursor: pointer;
                    display: flex;
                    height: 200px;
                    justify-content: center;
                    img{
                        width: 100%;
                        padding: 20px;
                    }
            }
        }
    }
    .category-images-container {
        .scrollable-sec {
            max-height: 250px;
            overflow: auto;
        }
    }
}

.add-product-input {
    padding: 20px 0;
}

.add-category-btn {
    display: flex;
    justify-content: flex-end;
}

.MuiList-root {
    &.MuiList-padding {
        padding: 0px !important;
    }
}

.category-indexing {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.del-category,
.edit-category {
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
}

.single-prduct-detail-container {
    .left-sec {
        padding-right: 15px;
        > .MuiGrid-root {
            box-shadow: 0 0 30px $black-shade;
            margin: 0 0 20px 0;
        }
        .product-details-listing {
            padding: 30px 30px 0;
            h4 {
                font-weight: 600;
                font-size: 26px;
            }
            p {
                padding: 0;
            }
            .effects-sec {
                .effects-listing {
                    display: flex;
                    flex-direction: row;
                    padding: 0;
                    .effect-chkbox {
                        width: 33.33%;
                        text-transform: capitalize;
                        label {
                            margin: 0 0 15px 0;
                            .MuiCheckbox-root {
                                padding: 0 5px 0 0;
                            }
                        }
                    }
                }
            }
        }
        .product-save-btn {
            display: flex;
            padding: 20px;
        }
    }
}

.sidebar-left-top-sec {
    .sidebar-left-top-logo {
        margin-top: -6px;
        height: 50px;
        width: 100%;
        img {
            padding: 0;
            max-height: 100%;
            max-height: 100%;
        }
    }
    button {
        padding: 0;
        margin-right: 10px;
    }
}

.appointments-page-container {
    .zipcode-sec {
        box-shadow: 0 0 30px $black-shade;
        padding: 40px 40px 20px;
        margin-bottom: 20px;
    }
    .add-appointment-btn {
        display: flex;
        justify-content: flex-end;
        button {
            height: 40px;
            margin-top: 8px;
        }
    }
    .MuiBox-root {
        flex-direction: column;
    }
    .blaze-zipcode-textarea {
        max-width: 100%;
        min-height: 100px;
        width: 100%;
        border-radius: 0;
        font-size: 15px;
        outline: none;
        resize: none;
        margin: 0 auto 15px;
        &:focus {
            border-color: $black-shade;
            outline: none;
            background-color: $white-color;
            box-shadow: none;
        }
        &::placeholder {
            color: $black-shade;
        }
    }
    .save-zipcode-btn {
        height: 40px;
        max-width: 150px;
        border-radius: 0px;
        border: none;
        outline: none;
        margin: 0;
        background: $black-color;
        color: $white-color;
        font-size: 15px;
        cursor: pointer;
        box-shadow: none !important;
        font-weight: 500;
        text-transform: uppercase;
        width: 100%;
        &:hover {
            background-color: $edit-dark;
        }
    }
}

.last-sync {
    padding: 15px;
    align-items: center;
    margin-bottom: 20px !important;
    font-weight: 500;
    color: var(--primary) ;
    display: flex;
    justify-content: space-between;
    border-radius: 0;
    position: fixed;
    right: 10px;
    top: 0px;
    z-index: 9999;
    width: auto;
    .product-sync-div {
        background-color: var(--primary) ;
        color: $white-color;
        align-items: center;
        height: 35px;
        display: flex;
        justify-content: center;
        width: 120px;
        font-size: 14px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
        margin-left: 10px;
        svg {
            color: var(--primary-light) ;
        }
        &.disabled-text {
            color: var(--primary-light) ;
            cursor: default !important;
        }
    }
}
.revenue-year-box ,
.fixed-top {
    position: fixed;
    z-index: 9999;
}
.revenue-year-box {
    top: 16px;
    right: 30px;
}
.fixed-top {
    top: 10px;
    right: 24px;
}
main {
    position: relative;
    &.promotions-container {
        height: auto;
    }
}
body{
    .manage-product-section, .dashboard-page {
        position: relative;
    }
}
.promotions-specials-sec {
    border: 0px solid $black-color;
    padding: 0;
    margin-bottom: 25px !important;
    margin-top: 40px !important;

    .specials-top-sec {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $black-shade;
        padding: 12px;
        border-radius: 0px;
        margin-bottom: 35px;
        .info {
            display: flex;
            align-items: center;
            svg {
                width: 18px;
                height: 18px;
                margin-right: 10px;
            }
        }
        button {
            height: 46px;
        }
    }

    .special-products-listing {
        .delivery-partner {
            .special-sec-list {
                width: 100%;
                display: flex;
                position: relative;
                align-items: center;
                padding: 15px;
                margin-top: 10px;
                background-color: $white-color;
                margin-bottom: -1px;
                &:focus {
                    outline: none;
                }
                h6 {
                    display: flex;
                    svg {
                        margin-right: 20px !important;
                        width: 20px;
                        height: 20px;
                        margin-right: 6px;
                    }
                }
                .del-special-product {
                    float: right;
                    align-items: center;
                    margin-left: 10px;
                    position: absolute;
                    right: 12px;
                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
}

.promotions-container {
    .top-btn-sec {
        display: flex;
        justify-content: flex-end;
        padding: 0 0 20px;
        button {
            height: 40px;
        }
    }

    .promotions-table {
        .promo-name {
            width: 20%;
        }
    }
}

body {
    .MuiButton-containedPrimary {
        background-color: var(--secondary-light) ;
        height: 48px !important;
        min-width: 180px;
        border-radius: 0 !important;
        box-shadow: none !important;
        text-transform: uppercase;
        font-weight: bold;
        color: $black-color;
        font-size: 16px;
        &:hover,
        &:focus {
            background-color: var(--secondary);
            color: $white-color;
        }
        &.secondary-btn {
            background-color: $black-color;
            color: $white-color;
            &:hover,
            &:focus {
                background-color: var(--secondary);
                color: $white-color;
            }
        }
    }
    .MuiCheckbox-colorPrimary {
        &.Mui-checked {
            color: var(--secondary);
        }
    }
    .manage-category-page-container {
        padding-bottom: 100px;
        .add-category-btn {
            margin: 0 0 20px;
            button {
                height: 46px;
            }
        }
        .save-button-div {
            padding-top: 10px;
        }
    }
    .confirmation-modal {
        button {
            height: 46px;
            min-width: 100px;
            margin: 0 6px;
        }
        h5 {
            font-size: 18px;
            margin-bottom: 20px;
        }
    }
}


.cus-public-header {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 100vh;
    background: $shade1;

    .login-custom-width {
        background: $white-color;

        img.logimg-icon {
            width: 100%;
            object-fit: cover;
        }

        .form-wrapper {
            border: 2px solid $shade1;
            border-right: 0;
        }
    }
}

.appbar-loggedin-email {
    margin: 0px;
    color: $black-shade;
    margin-right: 10px;
}
.appbar-logout-btn {
    color: var(--primary)  !important;
    cursor: pointer;
    &:hover {
        color: var(--primary)  !important;
    }
}

.admin-container {
    .custom-button-div {
        width: 180px;
        float: right !important;
    }
    .password-table-row {
        position: relative;
        .password-span {
            padding-left: 25px;
        }
        .show-icon {
            position: absolute;
            top: 35%;
            svg {
                cursor: pointer;
                font-size: 18px;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.table-loading {
    display: flex;
    justify-content: center;
}

.add-trucks-container {
    .top-sec {
        display: flex;
        justify-content: space-between;
        padding: 0 0 20px;
        .right-sec {
            button {
                margin: 0 5px;
            }
        }
    }
    .truck-form-container {
        -webkit-box-shadow: 0 0 30px $shade1;
        -moz-box-shadow: 0 0 30px $shade1;
        box-shadow: 0 0 30px $shade1;
        padding: 20px;
    }
}
.trucks-form-container {
    .trucks-form {
        display: flex;
        flex-direction: column;
        align-items: end;
        padding: 10px;
        h4 {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 10px;
            &.zipcode-title {
                display: flex;
                align-items: center;
            }
        }

        .ordering-hours-listing {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            .ordering-hours-title {
                width: 40%;
                text-align: left;
            }
        }
    }
}

.trucks-container {
    .top-btn-sec {
        display: flex;
        justify-content: flex-end;
        padding: 0 0 20px;
        button {
            height: 40px;
        }
    }
}

.map-sec-container {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    height: 300px;
    margin-top: 20px;
    .map-sec {
        width: 100%;
        display: inline-block;
        min-height: 300px;
    }
}

.truck-form-container {
    background-color: $white-color;
    border-radius: 4px;
    padding: 10px;
    .add-zipcode-icon {
        font-size: 24px;
        margin-left: 7px;
        cursor: pointer;
    }
}
.cus-table-dropdown {
    .MuiIconButton-root:hover {
        background-color: transparent;
    }
}
.edit-pop-up {
    ul {
        li {
            background-color: transparent !important;
            cursor: inherit;
        }
    }
}

.cust-google {
    width: 100%;
}

.add-notification-grid-item {
    margin: auto !important;
    box-shadow: 0 0 30px $black-shade;
    padding: 30px;
    border-radius: 4px;
}

.view-cart-item-btn {
    height: 40px !important;
    min-width: 100px !important;
    font-size: 12px !important;
}
.popover-style {
    .MuiStack-root {
        padding: 0;
    }
    li {
        text-align: left;
        justify-content: flex-start;
        padding: 6px 20px;
        width: 100%;
        margin: 0;
        border: 0 !important;
        border-radius: 0px !important;
        &:first-child {
            border-bottom: 1px solid var(--secondary-color-light)  !important;
        }
    }
    .MuiStack-root {
        padding: 0;
    }
}
.MuiOutlinedInput-input {
    padding: 14.5px 14px !important;
}

.edit-icon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    padding: 7px;
    background: $edit-light;
    color: $edit-dark;
    display: inline-block;
    svg {
        width: 18px;
        height: 18px;
    }
}
.eye-icon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    padding: 6px;
    background: var(--secondary-color-light) ;
    color: var(--secondary);
    text-align: center;
    display: inline-block;
    margin-right: 10px;
    svg {
        width: 18px;
        height: 18px;
    }
}
.delete-icon{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    padding: 6px;
    background: $red-color-light;
    color: $red-color;
    margin-left: 10px;
    text-align: center;
    display: inline-block;
    svg {
        width: 18px;
        height: 18px;
    }
}

html {
    body {

        .MuiInputLabel-outlined {
            -ms-transform: translate(14px, 16px) scale(1);
            -webkit-transform: translate(14px, 16px) scale(1);
            -moz-transform: translate(14px, 16px) scale(1);
            -o-transform: translate(14px, 16px) scale(1);
            transform: translate(14px, 16px) scale(1);
        }
    }
}

.effects-listing {
    width: 100%;
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin: 0;
   .innerGridstyle {
        > div {
            display: flex;
            flex-direction: row;
            border: 2px solid $black-shade;
            padding: 10px;
            border-radius: 8px;
            justify-content: flex-start;
            label {
                margin: 0;
                height: 24px;
                span{
                    padding: 0;

                }
                svg{
                    path {
                        fill: $black-shade;
                    }
                }
            }
            img {
                width: 24px;
                height: 24px;
                margin: 0 5px;
            }
            p{
                margin: 0;
                color: $black-shade;
            }
        }
        .unselect {
            label, img {
                opacity: 0.5;
            }
        }
        .select {
            label, img {
                opacity: 1;
            }
        }

   }
}
.table-width {
    text-align: center;
    table {
        width: 100%;
        thead th {
            font-weight: 800;
        }
    }
}
.delivery-hours-timing {
        display: flex;
        justify-content: space-between;
        width: 100%;
        position: relative;
    .hours-field {
        width: 100%;
        .MuiAutocomplete-input {

            padding: 5px 0 !important;
        }
    }
    .remove-field {
        color: $red-color;
        position: absolute;
        right: -20px;
        top: 10px;
        width: 18px !important;
    }
}
.add-more-fields {
    cursor: pointer;
    display: inline-block;
    float: right;
    margin-top: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $black-color;
    text-decoration: underline;
}
.back-btn {
    height: 38px;
    margin-right: 5px;
    h2 {
        font-size: 20px;
    }
}
.theme-list > div {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.mt-60 {
    margin-top: 60px;
}

.header-modal {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    padding: 16px;
    background: var(--secondary-color-light) ;
    z-index: 9;
    h3 {
        padding: 0;
        margin: 0;
        color: $black-color;
    }
}
.add-bran-width {
    width: 480px;
    input {
        padding: 6px !important;
    }
}

.MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
        border-width: 1px !important;
    }
}
.list-label {
     .MuiAccordion-root {
        border-bottom: 1px solid $shade2;
        &.Mui-expanded:last-child {
            padding-bottom: 10px;
        }
        > .Mui-expanded{
            border-bottom: 1px solid var(--secondary-color-light);
            .MuiAccordion-root {
                border-bottom: 1px solid var(--secondary-color-light);
            }
        }
        &.Mui-expanded {
            border-bottom: 1px solid var(--secondary-color-light);
        }

    }
    .accordion-style {
        padding: 10px 30px;
        .MuiAccordionSummary-content{
            margin: 0;
        }
        &.Mui-expanded{
            background: var(--secondary-color-light);
            margin-bottom: 20px;
        }
    }
    .MuiAccordionDetails-root {
        display: flex;
        padding: 8px 30px 30px;
    }
    strong {
        display: block;
        margin-top: 14px;
    }
    textarea {
        padding: 10px;
    }
}
.MuiDivider-root {
    background-color: $shade3;
}



.total-revenue-box-bg, .totalOrder-box-bg,
.totalOnboarded-box-bg, .totalProductSold-box-bg{
    color: var(--black-shade);
    border: 1px solid $shade1;
    border-radius: 10px;

    svg {
        width: 50px !important;
        height: 50px !important;
        padding: 10px;
        border-radius: 50%;
        background-color: var(--primary-theme);
        path{
            fill: $white-color;
        }
    }
    h3{
        color: var(--primary-theme);
        font-size: 32px;
        font-weight: 700;
        line-height: 1.5;
        letter-spacing: 0;
        font-family: Public Sans,sans-serif;
    }
    h6{
        color: $black-color;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 0;
        font-family: Public Sans,sans-serif;
    }
    div {
        margin-bottom: 0;
    }
}

.account-hyperwolf {
    .logo-theme {
        background: var(--primary-theme);
    }
}

.weekdAY {
    height: 64px;
    width: 64px;
    cursor: pointer;
    line-height: 64px;
    border-radius: 50%;
    text-align: center;
    border-color: var(--primary-theme);
    border: 1px solid var(--primary-theme);
    margin: 10px 20px 0 0;
    &.active {
        background: var(--secondary-color-light);
    }
}

.header-modal + div{
    .list-view:nth-child(1) {
        border: 0;
    }
}
.list-view{
    padding: 5px 0;
    border-top: 2px solid $shade1;
    p {
        margin: 0px;
        padding: 10px;
    }
}
.dailogBox{
    textarea {
        padding: 0 !important;
    }
    main {
        position: static;
    }
    textarea{
         &.meta-description {
            font-size: 16px;
            padding: 14.5px 14px !important;
            margin-top: 16px;
            margin-bottom: 8px;
        }
    }
}

.copy-to-all-products {
    border: 1px solid $shade4;
    padding: 20px 10px 10px;
}
.search-section {
        justify-content: space-between;
}
.MuiTable-stickyHeader {
    border-collapse: separate;
}
.MuiTableCell-stickyHeader {
    top: 0;
    left: 0;
    z-index: 2;
    position: sticky;
    background-color: var(--secondary-color-light);
}
td.more, th.more {
    position: -webkit-sticky;
    position: sticky;
    width: auto;
    right: 0;
    background: $white-color;
}
.list-grid-style {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 24px;
    grid-auto-rows: minmax(80px, auto);
    list-style: none;
    padding: 0px 0px;

     img {
        width: 110px;
        height: 40px;
        object-fit: contain;
        object-position: center;
        margin: 18px auto 10px;
    }
    .list-style-ui {
        align-items: center;
        padding: 14px;
        justify-content: space-between;
        border: 1px solid $shade1;
        .MuiSwitch-root{
            .MuiButtonBase-root{
                position: absolute;
                padding: 9px;
            }
        }
    }
    .MuiSwitch-colorPrimary.Mui-checked {
        color: $black-color !important;
    }
    .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track{
      background-color: #00000047 !important;
    }
    .card-actions {
        button {
            color: $black-color;
        }
    }
}
.list-style-upcoming {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 18px;
    list-style: none;
    margin-bottom: 60px;
    img {
        width: 100px;
        height: 40px;
        object-fit: contain;
        object-position: center;
        margin: 16px;
    }

}

.search-btn-drop-down-filter{
    &:before, &:after {
        border-bottom-color: transparent !important;
        border-bottom: 0px;
    }
    div{
        background: $white-color !important;
    }

}
.search-btn-drop-down-filter {
    border: 1px solid $shade2;
    padding: 2px 12px 0px;
    margin-left: 10px;
    min-width: 120px;
}

.setting-popover{
    .edit-icon {
        margin-right: 10px;
    }
   .delete-icon {
        margin-left: 0;
        margin-right: 10px;
    }
}
div#menu-discountType {
    z-index: 4444 !important;
}
.disable-input {
    .MuiOutlinedInput-input {
        padding: 16.5px 14px !important;
    }
}
.logo-theme {
    a {
        text-align: center;
        width: 100%;
        display: inline-block;
        img{
            display: block;
            max-width: 100%;
            height: 60px;
            margin: 0 auto;
            object-fit: contain;
        }
    }
}
@media (min-width: 1600px) {
    .list-grid-style img {
        width: 140px;
        height: 80px;
        margin: 18px auto 20px;
    }

}


// .account-cannamobile{
    .shop-change{
        position: absolute;
        right: 24px;
        top: 14px;
        z-index: 88;
        cursor: pointer;
        color: $black-color;
        button {
            border: none;
            font-weight: bold;
            width: auto;
            padding: 12px 45px 12px 15px;
            font-size: 14px;
            cursor: pointer;
            display: inline-block;
            border-radius: 0;
            line-height: normal;
            text-transform: none;
            letter-spacing: 0;
            font-family: Public Sans,sans-serif;
            &.btn-change-shop {
                background-color: var(--primary-theme-dark);
                color: $white-color;
                position: relative;
                text-align: left;
                &:before {
                    content: "";
                    border: solid $white-color;
                    border-width: 0 2px 2px 0;
                    display: inline-block;
                    padding: 3px;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    position: absolute;
                    right: 15px;
                    top: 14px;
                }
            }
        }
        .change-inner {
            position: absolute;
            right: 0;
            top: 41px;
            background: #f9f9f9;
            border-radius: 0;
            box-shadow: 0 0 12px #b3b3b3;
            overflow: hidden;
            z-index: -1;
            button{
                &.btn-shop-locations {
                    width: 100%;
                    display: inline-block;
                    background-color: transparent;
                    padding: 14px 10px 14px 32px;
                    text-align: left;
                    border-bottom: 1px solid #eeeeee;
                    color: var(--primary-theme-dark);
                    &:hover, &:focus {
                        background-color: var(--secondary-light);
                    }
                    &.selected-location {
                        background-color: var(--secondary-light);
                        position: relative;
                        &:before{
                            content: "";
                            display: inline-block;
                            transform: rotate(45deg);
                            height: 13px;
                            width: 7px;
                            border-bottom: 3px solid var(--primary-theme-dark);
                            border-right: 3px solid var(--primary-theme-dark);
                            position: absolute;
                            left: 14px;
                            top: 14px;
                        }
                    }
                    &:last-child{
                        border-bottom: none;
                    }
                }
            }
        }
    }
    .last-sync {
        position: static;
        width: 100%;
        padding: 15px 0;
        z-index: 0;
    }
    .revenue-year-box, .fixed-top {
        position: static;
        z-index: 0;
    }
    .fixed-top {
        margin-bottom: 20px;
    }
// }

.img-uploaded {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    list-style: none;
    padding: 0;
    margin: 0;
    li{
        width: 100%;
        height: 100px;
        position: relative;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover
        }
        span{
            position: absolute;
            right: -6px;
            top: -6px;
            background: #ff0000;
            color: #ffffff;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            svg{
                width: 16px;
                height: 16px;
            }
        }
    }
}
.banner-info{
    font-size: 13px;
    color: #ff0000;
}

body{
    .dash-grid-sec {
        width: calc(100% + 24px);
        margin: -12px;
        margin-top: 0;
        margin-bottom: 0;
        .mt-2 {
            padding: 12px;
        }
    }
    .dash-cart-tittle{
        span {
            font-family: Public Sans,sans-serif !important;
            font-weight: 700;
            font-size: 1.125rem;
            letter-spacing: 0;
        }
    }
}


.shop-banner {
    width: 100%;
    display: inline-block;
    .add-button{
        button {
            max-width: 220px;
        }
    }
    .img-uploaded{
        li {
            width: 100%;
            height: auto;
            margin-bottom: 10px;
            background-color: #f7f7f7;
            padding: 20px;
            &:last-child{
                margin-bottom: 0;
            }
            .slider-shop-img {
                width: 100%;
                height: 150px;
                position: relative;
                background-color: #ffffff;
            }
            .slider-link {
                width: 100%;
                display: inline-block;
                margin-top: 20px;
                .MuiFormControl-root{
                    background-color: #ffffff;
                }
            }
            .MuiInputLabel-outlined{
                &.MuiInputLabel-shrink {
                    background-color: #f7f7f7;
                }
            }
        }
    }
}
